<template>
	<PageLayout wrapper-class="education-category-wrapper" :title="$t('education.education')" large-width>
		<div v-if="loading" class="loading-wrapper">
			<loader />
		</div>
		<div v-if="error !== null && !loading">
			{{ error }}
		</div>
		<div v-if="error === null && !loading" class="layout">
			<div class="layout__category">
				<EducationCategoryCard
					:title="category.title"
					:allowed="true"
					:token="category.token"
					:category-id="category.categoryId"
					:preview="true"
				/>
			</div>
			<div class="layout__items">
				<EducationContentCard v-for="content in category.contents" :key="content.contentId" v-bind="content" />
				<span v-if="!category.contents || category.contents.length === 0" class="no-items-label">
					{{ $t('global.no-items') }}
				</span>
			</div>
		</div>
	</PageLayout>
</template>
<script>
import Loader from '@/shared/loader/Loader';
import apiClient from '@/api';
import EducationCategoryCard from '@/shared/education-category-card/EducationCategoryCard';
import EducationContentCard from '@/shared/education-content-card/EducationContentCard';
import PageLayout from '@/components/page-layout/PageLayout';

export default {
	name: 'EducationCategory',
	components: {
		PageLayout,
		EducationContentCard,
		EducationCategoryCard,
		Loader,
	},
	data() {
		return {
			category: null,
			error: null,
			loading: true,
		};
	},
	mounted() {
		this.getCategory();
	},
	methods: {
		async getCategory() {
			const categoryId = this.$route.params.id;
			try {
				const response = await apiClient.getEducationCategory(categoryId);
				this.category = response.category;
			} catch (e) {
				this.error = e.message;
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>
<style scoped lang="scss">
@import 'src/assets/style/_variables';
@import 'src/assets/style/mixin';

::v-deep .education-category-wrapper {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.layout {
	flex: 1;
	display: flex;
	flex-direction: column;

	@include min-screen($md + 1) {
		flex-direction: row;
	}

	&__category {
		@include min-screen($md + 1) {
			flex: 1;
		}
	}

	&__items {
		@include min-screen($md + 1) {
			flex: 4;
		}
	}
}

.no-items-label {
	display: block;
	margin-top: 1rem;
}
</style>
