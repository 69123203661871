<template>
	<div class="category-item">
		<div class="category-item__title">
			<strong>{{ title }}</strong>
		</div>
		<div class="category-item__action">
			<a
				class="category-item__action__link"
				:href="isLink ? contentLink : `${url}/education/contentFile/${contentFileToken}`"
				target="_blank"
			>
				<img
					v-if="isLink"
					class="category-item__action__image"
					:style="{ marginLeft: '2px' }"
					:src="require('../../assets/dashboard-icons/play.svg')"
					alt="play-icon"
				/>
				<span v-else> PDF </span>
			</a>
		</div>
	</div>
</template>

<script>
export default {
	name: 'EducationContentCard',
	props: {
		title: {
			type: String,
		},
		contentLink: {
			type: String,
			default: null,
		},
		contentFileToken: {
			type: String,
			default: null,
		},
		contentFileName: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			url: process.env.VUE_APP_URL,
		};
	},
	computed: {
		isLink() {
			return this.contentLink !== null;
		},
	},
};
</script>

<style lang="scss" scoped>
@import 'src/assets/style/mixin';
@import 'src/assets/style/variables';

.category-item {
	height: 60px;
	background: #1d2024;
	border: 1px solid rgba(255, 255, 255, 0.1);
	border-radius: 5px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 15px;
	margin-bottom: 10px;

	&__title {
		flex: 1;
		text-align: start;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	&__action {
		&__link {
			font-size: 12px;
			display: flex;
			justify-content: center;
			align-items: center;
			border: 1px solid rgba(255, 255, 255, 0.1);
			border-radius: 50%;
			width: 40px;
			height: 40px;
			background-size: 120% auto;
			background-image: linear-gradient(134.22deg, $abbey-gray -63.52%, $shark-black 74.66%);

			&:hover {
				@include button-hover;
			}
		}
	}
}
</style>
